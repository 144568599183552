import { useAppState } from '../../app/contexts/AppStateContext';
import { Map } from '../../components/Map/Map';
import { TMapTypeSelectorType } from '../../components/MapLayerSelector/MapTypeSelector';
import { MapTypeLightning } from '../../components/MapTypeLightning/MapTypeLightning';
import { MapTypeRadar } from '../../components/MapTypeRadar/MapTypeRadar';
import { MapTypeTemperature } from '../../components/MapTypeTemperature/MapTypeTemperature';
import { MapTypeWeather } from '../../components/MapTypeWeather/MapTypeWeather';
import { MapTypeWind } from '../../components/MapTypeWind/MapTypeWind';
import { PageRoot } from '../../components/PageRoot/PageRoot';
import { useFetchLocation } from '../../data/locations/hooks';
import { useCurrentLocationId } from '../../lib/hooks';
import { useTranslate } from '../../lib/hooks/useTranslate';
import { locationSupportsRadarOrLightning } from '../../modelHelpers/locations';
import './MapPage.scss';

export default function MapPage() {
  const { currentPage } = useAppState();
  const { subpageId } = currentPage.details.params;
  const { embedded } = currentPage.details.query;

  const locationId = useCurrentLocationId();
  const translate = useTranslate();

  const { data: location } = useFetchLocation({ locationId });

  if (location == null || locationId == null) {
    return null;
  }

  const supportsRadarOrLightning = locationSupportsRadarOrLightning(location);

  // If the user attempts to view the radar or lightning map outside the supported region we should show an error
  if ((subpageId === 'radar' || subpageId === 'lightning') && supportsRadarOrLightning === false) {
    return (
      <PageRoot className="map-page">
        <p className="map__error">{translate(`mapPage/unavailable/${subpageId}`)}</p>
      </PageRoot>
    );
  }

  return (
    <PageRoot className="map-page" isContainer={true}>
      <Map
        cursor={'arrow'}
        mapTypeSelector={{
          currentMapType: subpageId as TMapTypeSelectorType,
          mapTypes: {
            weather: true,
            radar: supportsRadarOrLightning,
            wind: true,
            lightning: supportsRadarOrLightning,
            temperature: true
          }
        }}
        shouldShowLegend={embedded === false}
        shouldShowFullScreenButton={embedded === false}
        shouldShowZoomToolbar={embedded === false}
        shouldShowInfoButton={embedded === false}
        shouldShowMapTypeSelector={embedded === false}
        render={({ map, setAriaLabel, styles }) => {
          return (
            <>
              {subpageId === 'weather' && (
                <MapTypeWeather locationId={locationId} map={map} style={styles.default} setAriaLabel={setAriaLabel} />
              )}
              {subpageId === 'radar' && (
                <MapTypeRadar map={map} style={styles.defaultWithName} setAriaLabel={setAriaLabel} />
              )}
              {subpageId === 'wind' && <MapTypeWind map={map} style={styles.heatmap} setAriaLabel={setAriaLabel} />}
              {subpageId === 'lightning' && (
                <MapTypeLightning map={map} style={styles.defaultWithName} setAriaLabel={setAriaLabel} />
              )}
              {subpageId === 'temperature' && (
                <MapTypeTemperature map={map} style={styles.heatmap} setAriaLabel={setAriaLabel} />
              )}
            </>
          );
        }}
      />
    </PageRoot>
  );
}
